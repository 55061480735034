<template>
  <div class="fullpage" ref="fullPage">
    <div
      class="container"
      ref="container" 
      @mousewheel="mouseWheelHandle"
      @DOMMouseScroll="mouseWheelHandle">
      <slot></slot>
    </div>
  </div>
</template>
<script setup>
  import { reactive,defineProps,ref,defineEmits } from 'vue';
  let fullPage=ref(null);//容器
  let container=ref(null);
  let props = defineProps({
    len:Number
  })
  let emits=defineEmits(['scroll']);
  let data=reactive({
    current: 1, // 当前的页面编号
    isScrolling: false, // 是否在滚动
    deltaY: 0 // 用来判断是往下还是往上滚
  })
  // 往下切换
  const next = ()=> { 
    if (data.current + 1 <= props.len) { 
      data.current += 1; // 页面+1
      move(data.current); // 执行切换
    }
  }
  // 往上切换
  const pre =() =>{
    if (data.current - 1 > 0) { 
      data.current -= 1;// 页面+1
      move(data.current);// 执行切换
    }
  }
  const move=(index)=> {
    data.isScrolling = true;
    directToMove(index); //执行滚动
    setTimeout(() => { 
      data.isScrolling = false;
    },1010);
  }
  const directToMove = (index)=> {
    let height = fullPage.value.clientHeight; 
    let scrollPage = container.value;
    let scrollHeight; // 计算滚动的告诉，是往上滚还往下滚
    scrollHeight= -(index - 1) * height + "px";
    scrollPage.style.transform = `translateY(${scrollHeight})`;
    data.current = index;
  }
  const mouseWheelHandle = (event) => { // 监听鼠标监听
    // 添加冒泡阻止
    let evt = event || window.event;
    if (evt.stopPropagation) {
      evt.stopPropagation();
    } else {
      evt.returnValue = false;
    }
    if (data.isScrolling) { // 判断是否可以滚动
      return false;
    }
    let e = event.originalEvent || event;
    data.deltaY = e.deltaY || e.detail; // Firefox使用detail
    emits('scroll',data.deltaY);
    if (data.deltaY > 0) {
      next();
    } else if (data.deltaY < 0) {
      pre();
    }
  }
</script>
<style lang="scss" scoped>
.fullpage{
  width: 100%;
  height: 100%;//一定要设置，保证占满
  overflow: hidden;//一定要设置，多余的先隐藏
  .container{
    width: 100%;
    height: 100%;
    transition: all linear 0.5s;
  }
}
</style>