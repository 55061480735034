<template>
  <div class="index">
    <fullpage :len="6" @scroll="scrollPage">
      <div class="section" id="section1">
        <img class="banner" src="https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/pc/part1-bg.png" alt="">
      </div>
      <div class="section" id="section2">
        <img src="https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/pc/part2-title.png" alt="" class="title">
        <div class="subTitle">可批量快速产出，适应各个平台，避开AI检测设备</div>
        <div class="tab">
          <template 
          v-for="(item) in data.tabList" 
          :key="item.value">
            <div 
            :class="{'tabItem':true,'selectd':data.tab==item.value}"
            @click="changeTab(item)">
              {{item.text}}
            </div>
          </template>
          
        </div>
        <div class="imgList" v-if="data.currentItem">
          <div class="model">
            <img :src="data.currentItem.model" alt="">
            <div class="name">企业定制模型</div>
          </div>
          <div class="arrow">
            <img src="https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/pc/rightBig.png" alt="">
          </div>
        
          <div class="product">
            <div class="wrap" ref="imgScroll">
              <template v-for="(item,index) in  data.currentItem.list" :key="index">
                <img :src="item" alt="">
              </template>
            </div>
            <div class="name">
              公有大模型
              <img 
              @click="scrollRight" 
              src="https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/pc/rightSmall.png" alt="">
            </div>
        
          </div>
        </div>
      </div>
      <div class="section" id="section3">
        <img src="https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/pc/part3-bg.png" alt="" class="bg">
        <img src="https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/pc/part3-title.png" alt="" class="title">
        <div class="text1">
          <img src="https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/pc/part3-1.png" alt="">
          <div class="desc" style="text-align:left">
            普通员工学习AIGC需要 <br/>
            一定的时间成本且不能快<br/>
            速满足企业批量化产出的<br/>
            需求产出质量无法控制
          </div>
        </div>
        <div class="text2">
          <img src="https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/pc/part3-2.png" alt="">
          <div class="desc">
            少量原始素材图片即可<br/>
             批量化输出同一产品输出<br/>
             300张图仅需3-5天
          </div>
        </div>
        <div class="text3">
          <img src="https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/pc/part3-3.png" alt="">
          <div class="desc">
            可为企业丰富所有品线<br/>
            的模型库训练质量可控<br/>
            拓展性强模型可无限复用
          </div>
        </div>
        <div class="text4">
          <img src="https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/pc/part3-4.png" alt="">
          <div class="desc">
            无需模特、布景、商拍、<br/>
            达人样货等成本<br/>
            出图成本降低80%
          </div>
        </div>

      </div>
      <div class="section" id="section4">
        <img src="https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/pc/part4-bg.png" alt="" class="bg">
        <img src="https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/pc/part4-title.png" alt="" class="title">
        <div class="subtitle">5步专业化训练，输出更符合企业需求的智能模型</div>
        <div class="step">
          <template 
            v-for="(item,index) in data.stepList" 
            :key="index">
            <div class="stepItem">
              <div class="icon">
                <img :src="item.icon" alt="">
              </div>
              <div class="line">
                <div class="mask" v-if="index==0"></div>
                <div class="mask1" v-if="index==4"></div>
              </div>
              <div class="title2">{{item.title}}</div>
              <div class="desc" v-html="item.desc"></div>
            </div>
          </template>
         
        </div>
      </div>
      <div class="section" id="section5">
        <img src="https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/pc/part5-title.png" alt="" class="title">
        <div class="subtitle">
          最少训练数据，训练数据独立存储训练结果直接输出，独立部署企业云盘
        </div>
        <div class="wrap">
          <img src="https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/pc/part5-bg.png" alt="" class="bg">
          <div class="mainTitle">
            <img src="https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/pc/part5-mainTitle.png" alt="">
          </div>
          <div class="text text1">
            <div class="content textRight">
              <div class="ctitle">数据安全</div>
              <div class="text">
                可实现内外网隔离<br/>
                更好的控制和保护数据的安全<br/>
                客户数据、企业内部信息、企业资源<br/>
                全部存储在企业自有服务器中
              </div>
            </div>
            <img src="https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/pc/part5-1.png" alt="">
          </div>
          <div class="text text2" >
            <img src="https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/pc/part5-2.png" alt="">
            <div class="content textLeft" >
              <div class="ctitle">定制化程度高</div>
              <div class="text">
                对企业适应性强<br/>
                能满足不同企业的实际应用需求<br/>
                还可以集成到各种应用程序和服务中
              </div>
            </div>
          </div>
          <div class="text text3">
            <div class="content textRight">
              <div class="ctitle">高可控性</div>
              <div class="text">
                控制权掌握在企业自己手中<br/>
                企业可以自行管理控制调整资源<br/>
                也可以按需自己升级和维护模型
              </div>
            </div>
            <img src="https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/pc/part5-3.png" alt="">
          </div>
          <div class="text text4">
            <img src="https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/pc/part5-4.png" alt="">
            <div class="content textLeft">
              <div class="ctitle">拓展性强</div>
              <div class="text">
                支持企业自主训练<br/>
                自己创建应用适合企业需求的应用<br/>
                并不断训练达到企业要求<br/>
                且资料私密性强
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section" id="section6">
        <img src="https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/pc/part6-bg.png" alt="" class="bg">
        <img src="https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/pc/part6-title.png" alt="" class="title">
        <div class="logoList">
          <template v-for="item in 24" :key="item">
            <div class="logoItem">
              <img :src="'https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/pc/logo'+item+'.png'" alt="">
            </div>
          </template>
          
        </div>
        <div class="footer">
          <div class="logo">
            <img src="https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/pc/logoTwo.png" alt="">
          </div>
          <div class="content">
            <div class="ctitle">解决方案</div>
            <div class="row">企业AIGC落地服务</div>
            <div class="row">数字营销整合方案</div>
            <div class="row">数字藏品创新营销服务</div>
          </div>
          <div class="content">
            <div class="ctitle">联系我们</div>
            <div class="row">TEL: 17174371777</div>
            <div class="row">邮箱：inspire@inspire.com</div>
          </div>
          <div class="qrcode">
            <img src="https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/pc/code.png" alt="">
          </div>
        </div>
        <div class="copyRight">
          沪ICP备2022008431号-1 上海蕴元信息科技有限公司
        </div>
      </div>
    </fullpage>   
  </div>
</template>
<script setup>
 import{inject,onMounted,reactive,ref }from "vue";
 import fullpage from "../components/fullpage.vue";
 let hanldShow =  inject('hanldShow');
 let imgScroll = ref(null);//图片滚动
 let data=reactive({
  tabList:[
    {
      text:'商拍供图',
      value:1
    },
    {
      text:'达人带货',
      value:2
    }, {
      text:'社媒投放',
      value:3
    }, {
      text:'电商首焦',
      value:4
    }, {
      text:'营销内容',
      value:5
    }, {
      text:'人像摄影',
      value:6
    }
  ],//导航列表
  tab:1,//当前导航
  stepList:[
    {
      icon:'https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/pc/part4-icon1.png',
      title:'产品/IP',
      desc:'企业原始产品/IP数据'
    },
    {
      icon:'https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/pc/part4-icon2.png',
      title:'模型参数训练',
      desc:'Stable Diffusion系列<br/> SDXL系列、ChatGLM系列 <br/>LLaMa系列、Baichuan系列'
    },
    {
      icon:'https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/pc/part4-icon3.png',
      title:'模型效果测评',
      desc:'将模型部署到相应位置'
    },
    {
      icon:'https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/pc/part4-icon4.png',
      title:'模型部署上线',
      desc:'测评训练之后的各项参数'
    },
    {
      icon:'https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/pc/part4-icon5.png',
      title:'平台应用API接口',
      desc:'支持企业自建应用<br/>SAAS平台，API接口接入'
    }
  ],
  currentIndex:0,//当前下标
  currentItem:null,//当前图片
 })
 let imgList=ref([
  {
    model:'https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/commercial/model.png',
    list:[
      "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/commercial/1.png",
      "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/commercial/2.png",
      "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/commercial/3.png",
      "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/commercial/4.png"
    ]
  },
  {
    model:"https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/expert/model.png",
    list:[
      "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/expert/1.png",
      "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/expert/2.png",
      "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/expert/3.png",
      "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/expert/4.png"
    ]
  },
  {
    model:"https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/social/model.png",
    list:[
      "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/social/1.png",
      "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/social/2.png",
      "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/social/3.png",
      "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/social/4.png"
    ]
  },
  {
    model:"https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/online/model.png",
    list:[
      "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/online/1.png",
      "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/online/2.png",
      "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/online/3.png",
      "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/online/4.png"
    ]
  },
  {
    model:"https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/market/model.png",
    list:[
      "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/market/1.png",
      "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/market/2.png",
      "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/market/3.png",
      "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/market/4.png"
    ]
  },
  {
    model:"https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/portrait/model.png",
    list:[
      "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/portrait/1.png",
      "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/portrait/2.png",
      "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/portrait/3.png",
      "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/portrait/4.png"
    ]
  }
 ])
 //滚动隐藏导航
 const scrollPage=(des)=>{
  hanldShow(des<0);
 }
  //切换tab
  const changeTab=(item)=>{
    data.tab=item.value;
    data.currentIndex=item.value-1;
    data.currentItem=imgList.value[data.currentIndex];
  }
  //滚动到右边
  const scrollRight=()=>{
    imgScroll.value.scrollLeft  =  imgScroll.value.scrollWidth;
  }
  onMounted(()=>{
    data.currentItem=imgList.value[data.currentIndex];
  })
</script>

<style lang="scss" scoped>
  .index{
    width: 100%;
    height: 100%;
    .section{
      width: 100%;
      height: 100%;
      overflow: hidden;
      transition: transform 0.8s ease-in-out;
    }
    #section1{
      font-size: 0;
      position: relative;
      .banner{
        position: absolute;
        bottom: 0;
        left: 0;
        width:100%;
      }
      .bannerText{
        position: absolute;
        right: 0.33rem;
        bottom: 0.28rem;
        width: 18rem;

      }
      .bannerTitle{
        position: absolute;
        top: 2.25rem;
        left: 0.52rem;
        width: 10rem;
         
      }
    }
    #section2{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      padding-top: 1rem;
      box-sizing: border-box;
      .title{
        height: 0.8rem;
      }
      .subTitle{
        font-family: Source Han Sans CN light;
        font-weight: 300;
        font-size:0.32rem;
        color: #000000;
        margin-top: 0.12rem;
      }
      .tab{
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-top: 0.2rem;
        .tabItem{
          width: 10%;
          height: 0.7rem;
          border-radius: 0.35rem;
          text-align: center;
          line-height: 0.7rem;
          margin-right: 0.2rem;
          border: 2px solid #696969;
          font-family: Source Han Sans CN;
          font-weight: 300;
          font-size: 0.24rem;
          color: #070707;
          cursor: pointer;
          letter-spacing: 4px;
        }
        .selectd{
          border: 2px solid #2b73db;
          color: #fff;
          background: linear-gradient(90deg,#69a7f4,#526df2);
        }
      }
      .imgList{
        width: 100%;
        padding: 0 0.6rem;
        box-sizing: border-box;
        font-size: 0;
        margin-top: 0.6rem;
         display: flex;
         flex-direction: row;
         align-items: flex-start;
         justify-content: center;
        .model{
          height: 4rem;
          img{
            height: 100%;
          }
          .name{
            font-family: Source Han Sans CN light;
            font-weight: 300;
            font-size: 0.3rem;
            color: #232323;
            margin-top: 0.16rem;
          }
        }
        .arrow{
          display:flex;
          height: 4rem;
          margin-left: 0.8rem;
          margin-right: 0.6rem;
          align-items: center;
          justify-content: center;
          img{
            width: 0.76rem;
            height: 1.5rem;
          }
        }
        .product{
          flex: 1;
          width: 0;
          .wrap{
            width: 100%;
            height: 4rem;
            overflow-x: auto;
            white-space: nowrap;
            padding-bottom: 4px;
            img{
              height: 100%;
              margin-right: 0.3rem;
              &:last-child{
                margin-right: 0;
              }
            }
          }
          .name{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            font-family: Source Han Sans CN light;
            font-weight: 300;
            font-size: 0.3rem;
            color: #232323;
            margin-top: 0.16rem;
            img{
              width: 0.24rem;
              cursor: pointer;
            }
          }
         
         
        }
      }
      .arrowBtn{
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-size: 0;
        padding-right:1.6rem;
        box-sizing: border-box;
        margin-top: 0.15rem;
        cursor: pointer;
        .title1{
          flex: 1;
          width: 0;
          text-align: left;
          font-family: Source Han Sans CN light;
          font-weight: 300;
          font-size: 0.14rem;
          color: #232323;
        }
        .title2{
          padding-left: 0.28rem;
          font-family: Source Han Sans CN light;
          font-weight: 300;
          font-size: 0.14rem;
          color: #232323;
        }
        img{
          width: 0.24rem;
        }
      }
    }
    #section3{
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      padding-top: 1rem;
      box-sizing: border-box;
      .bg{
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
      .title{
        position: relative;
        height: 2.2rem;
        z-index: 1;
      }
      .text1{
        width: 2.5rem;
        position: absolute;
        left: 1.28rem;
        bottom: 1.42rem;
        font-size: 0;
        img{
          width: 100%;
        }
      }
      .text2{
        width: 2.5rem;
        position: absolute;
        left: 5.7rem;
        bottom: 0.9rem;
        font-size: 0;
        img{
          width: 100%;
        }
      }
      .text3{
        width: 2.5rem;
        position: absolute;
        right: 5.7rem;
        bottom: 1.78rem;
        font-size: 0;
        img{
          width: 100%;
        }
      }
      .text4{
        width: 2.5rem;
        position: absolute;
        right: 1.5rem;
        bottom: 0.9rem;
        font-size: 0;
        img{
          width: 100%;
        }
      }
      .desc{
        font-family: Source Han Sans CN;
        font-weight: 400;
        font-size: 0.22rem;
        color: #000000;
        line-height: 0.3rem;
        margin-top: 0.12rem;
        text-align: center;
      }
    }
    #section4{
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      padding-top: 1rem;
      box-sizing: border-box;
      .bg{
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
      }
      .title{
        position: relative;
        height: 0.9rem;
        z-index: 1;
      }
      .subtitle{
        position: relative;
        z-index: 1;
        font-family: 'Source Han Sans CN light';
        font-weight: 300;
        font-size: 0.32rem;
        color: #000000;
        margin-top: 0.24rem;
      }
      .step{
        position: relative;
        z-index: 1;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;
        margin-top: 1rem;
        .stepItem{
          width: 3.44rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          .icon{
            width: 2.44rem;
            font-size: 0;
            img{
              width: 100%;
            }
          }
          .line{
            width: 100%;
            height: 2px;
            background: none;
            position: relative;
            margin-top: 0.4rem;
            margin-bottom: 0.5rem;
            .mask{
              width: 1rem;
              height: 4px;
              position: absolute;
              top: -1px;
              left: 0;
              background: linear-gradient(90deg,#F8FAFD,transparent);
            }
            .mask1{
              width: 1rem;
              height: 4px;
              position: absolute;
              top: -1px;
              right: 0;
              background: linear-gradient(270deg,#E4E5FC,transparent);
            }
            &::before{
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background: linear-gradient(to right, transparent 0%, transparent 50%, #66A1F3 50%,#66A1F3 100%);
              background-size: 30px 3px;
              background-repeat: repeat-x;
            }
            &::after{
              content: '';
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%,-50%);
              width: 0.16rem;
              height: 0.16rem;
              border-radius: 50%;
              background: #5837E4;
              z-index: 1;
            }
          }
          .title2{
            font-family: 'Source Han Sans CN bold';
            font-weight: bold;
            font-size: 0.36rem;
            color: #63A1F4;
            margin-bottom: 0.18rem;
          }
          .desc{
            font-family: Source Han Sans CN;
            font-weight: 400;
            font-size: 0.2rem;
            color: #000000;
            line-height: 0.3rem;
            text-align: center;
          }
        }
      }
    }
    #section5{
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      padding-top: 1rem;
      box-sizing: border-box;
 
      .title{
        position: relative;
        height: 0.9rem;
        z-index: 1;
      }
      .subtitle{
        position: relative;
        z-index: 1;
        font-family: 'Source Han Sans CN light';
        font-weight: 300;
        font-size: 0.32rem;
        color: #000000;
        margin-top: 0.24rem;
      }
      .wrap{
        position: relative;
        z-index: 1;
        margin-top: 0.8rem;
        width: 15.5rem;
        height: 5.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        .bg{
          height: 8rem;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
        }
        .mainTitle{
          position: absolute;
          top: 44%;
          left: 50%;
          transform: translate(-50%,-50%);
          height: 0.6rem;
        }
        .text{
          height: 1.6rem;
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          img{
            height: 100%;
          }
          .content{
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: space-between;
            .ctitle{
              font-family: 'Source Han Sans CN bold';
              font-weight: bold;
              font-size: 0.36rem;
              color: #000;
              margin-bottom: 0.08rem;
              line-height: 1;
            }
            .text{
              font-family: Source Han Sans CN;
              font-weight: 400;
              font-size: 0.2rem;
              color: #000000;
              line-height: 0.28rem;
            }
         
          }
          .textLeft{ 
            margin-left: 0.1rem;
            .ctitle{
              text-align: left;
            }
            .text{
              text-align: left;
            }
          }
          .textRight{
            margin-right: 0.1rem;
            .ctitle{
              text-align: right;
            }
            .text{
              text-align: right;
            }
          }
        }
        .text1{
          position: absolute;
          top: 0;
          left: 0.6rem;
        }
        .text2{
          position: absolute;
          top: 0;
          right: 1.4rem;
          height: 1.3rem;
        }
        .text3{
          position: absolute;
          bottom: 0;
          left:1rem;
          height: 1.3rem;
        }
        .text4{
          position: absolute;
          bottom: 0;
          right: 0.8rem;
          
        }
      }
    }
    #section6{
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      padding-top: 1rem;
      box-sizing: border-box;
      .bg{
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
      .title{
        position: relative;
        height: 0.8rem;
        z-index: 1;
      }
      .logoList{
        position: relative;
        z-index: 1;
        width: 16rem;
        margin-top: 0.8rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .logoItem{
          width: 1.5rem;
          height: 0.9rem;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 0.5rem;
          margin-bottom: 0.5rem;
          cursor: pointer;
          &:nth-child(8n){
            margin-right: 0;
          }
          img{
            max-height: 100%;
            max-width: 100%;
          }
        }
      }
      .footer{
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 2rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0 0.35rem 0 0.88rem;
        box-sizing: border-box;
        .logo{
          width: 3rem;
          font-size: 0;
          margin-right: 1rem;
          img{
            width: 100%;
          }
        }
        .content{
          margin-right: 1.45rem;
          .ctitle{
            font-family: Source Han Sans CN bold;
            font-weight: bold;
            font-size: 0.26rem;
            color: #3B125E;
            line-height: 1;
            margin-bottom: 0.16rem;
          }
          .row{
            font-family: Source Han Sans CN;
            font-weight: 400;
            font-size: 0.17rem;
            color: #000000;
            margin-top: 0.12rem;
            line-height: 1;
          }
        }
        .qrcode{
          flex: 1;
          text-align: right;
          height: 1.6rem;
          font-size: 0;
          img{
            height: 100%;
          }
        }
      }

    }
    .copyRight{
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      height: 0.4rem;
      line-height: 0.4rem;
      text-align: center;
      color: #595959;
      font-size: 0.12rem;
    }
  }
</style>